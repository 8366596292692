import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/nicholaswarner/dawnstar/MM-W/catalyst/node_modules/gatsby-theme-catalyst-core/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Seo = makeShortcode("Seo");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Seo title="A History of Muds" mdxType="Seo" />
    <h1 {...{
      "id": "mudmuseum---a-history-over-a-half-century"
    }}>{`MudMuseum - A History over a Half-Century`}</h1>
    <p>{`Multi-User Dungeons (MUDs) originated near the beginnings of the internet, relative to what we think of today. To quote
`}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/MUD",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`Wikipedia’s MUD entry`}</a>{`,`}</p>
    <blockquote>
      <p parentName="blockquote">{`A MUD is a multiplayer real-time virtual world, usually text-based. MUDs combine elements of role-playing games, hack
and slash, player versus player, interactive fiction, and online chat. `}</p>
    </blockquote>
    <p>{`If this sounds like an ancient predecessor to MMORPGs, you’d be correct,`}</p>
    <blockquote>
      <p parentName="blockquote">{`The history of modern massively multiplayer online role-playing games (MMORPGs) like EverQuest and Ultima Online, can
be traced directly back to the MUD genre.`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      